<template>
  <v-container fluid>
  </v-container>
</template>

<script>
export default {
    name: 'about-page',
    data () {
      return {}
    },
}
</script>

<style scoped lang="scss">

</style>